var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"width":"100%","max-width":"30rem","elevation":"0"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('div',{staticClass:"access-registration-bar"},[_c('div',{staticClass:"access-registration-bar__circle",class:{
          current: _vm.currentStep === 1,
          filled: _vm.currentStep === 1,
        }},[_c('div',{staticClass:"access-registration-bar__circle--content text-center"},[_vm._v("1")])]),_c('div',{staticClass:"access-registration-bar__line"},[_c('div',{staticClass:"access-registration-bar__line--content"})]),_c('div',{staticClass:"access-registration-bar__circle",class:{
          current: _vm.currentStep === 2,
          filled: _vm.currentStep === 2,
        }},[_c('div',{staticClass:"access-registration-bar__circle--content text-center"},[_vm._v("2")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }